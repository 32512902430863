// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-cell.file-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-cell.file-cell .img-container {
  width: 48px;
  height: 48px;
  margin-right: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 1px solid var(--clr-divider);
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.table-cell.file-cell .img-container img {
  width: 100%;
  height: 100%;
}
.table-cell.file-cell .img-container .ext {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/table/cell.scss"],"names":[],"mappings":"AACC;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;AAAF;AAEE;EACC,WAAA;EACA,YAAA;EACA,kBAAA;EACA,wCAAA;EACA,oCAAA;EACA,kBAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAAH;AAEG;EACC,WAAA;EACA,YAAA;AAAJ;AAGG;EACC,iBAAA;AADJ","sourcesContent":[".table-cell {\n\t&.file-cell {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\n\t\t.img-container {\n\t\t\twidth: 48px;\n\t\t\theight: 48px;\n\t\t\tmargin-right: 10px;\n\t\t\tbox-shadow: 0 2px 4px rgba($color: #000000, $alpha: 0.2);\n\t\t\tborder: 1px solid var(--clr-divider);\n\t\t\tborder-radius: 4px;\n\t\t\toverflow: hidden;\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: center;\n\n\t\t\timg {\n\t\t\t\twidth: 100%;\n\t\t\t\theight: 100%;\n\t\t\t}\n\n\t\t\t.ext {\n\t\t\t\tfont-weight: bold;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
