import React, { useContext } from "react";
import ContentTable from "../../components/table/table/ContentTable";
import "./style.scss";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import FileCell from "../../components/table_items/file_cell/FileCell";
import { useNavigate } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import ConfirmationContext from "../../contexts/ConfirmationContext";
import axios from "../../services/axios";

const columns = [
	{
		width: 120,
		headerName: "ID",
		field: "id",
	},
	{
		width: 120,
		headerName: "Name",
		field: "file",
		renderCell: (e) => <FileCell url={e.value} data={e} />,
	},

	{
		width: 120,
		headerName: "Description",
		field: "description",
	},
];

const Media = ({ medias, fetchMedias }) => {
	const navigate = useNavigate();
	const { open_confirm } = useContext(ConfirmationContext);

	const delete_medias = async (medias) => {
		for (let index = 0; index < medias.length; index++) {
			const media = medias[index];
			await axios.delete(`/media/${media.id}/`);
		}
		await fetchMedias();
	};

	return (
		<div id="media">
			<ContentTable
				onClick={(e) => navigate(`/media/${e.id}`)}
				create={(e) => navigate(`/media/new`)}
				columns={columns}
				pageSizeOptions={[10, 20, 50, 100]}
				title="Medias"
				data={JSON.parse(JSON.stringify(medias))}
				can_select={true}
				actions={[
					{
						title: "Delete selected medias",
						icon: <Delete />,
						action: (e) =>
							open_confirm(
								`Delete ${e.length} media${
									e.length > 1 ? "s" : ""
								}`,
								"This action is not reversible, please be careful",
								`Delete ${e.length} media${
									e.length > 1 ? "s" : ""
								}`,
								() => delete_medias(e)
							),
					},
				]}
			/>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Media);
