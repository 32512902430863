import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import AuthContext from "../../../contexts/AuthContext";
import { Button } from "@mui/material";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { useParams } from "react-router-dom";
import axios from "../../../services/axios";

const generate_dict_from_locale = (locales, entry) => {
	const dict = {};
	for (let index = 0; index < locales.length; index++) {
		const locale = locales[index];
		dict[locale.locale] = {
			initial_value: "",
			value: "",
			empty: true,
		};
	}

	dict[entry.locale.locale] = {
		initial_value: entry.content,
		value: entry.content,
		empty: false,
	};

	for (let index = 0; index < entry.entries.length; index++) {
		const ent = entry.entries[index];
		dict[ent.locale.locale] = {
			initial_value: ent.content,
			value: ent.content,
			empty: false,
		};
	}
	return dict;
};

const is_diff = (entry_data) => {
	let diff = false;
	Object.keys(entry_data).map((key, index) => {
		if (entry_data[key].initial_value != entry_data[key].value) {
			diff = true;
		}
	});
	return diff;
};

const EntryItem = ({ entry, fetchTranslations, translations }) => {
	const params = useParams();
	const { locales } = useContext(AuthContext);
	const [entryData, setEntryData] = useState(
		generate_dict_from_locale(locales, entry)
	);
	const loaded_entry = useRef(entry.id);

	useEffect(() => {
		if (loaded_entry.current != entry.id) {
			setEntryData(generate_dict_from_locale(locales, entry));
			loaded_entry.current = entry.id;
		}
	}, [entry]);

	const save = async () => {
		const to_update = [];
		Object.keys(entryData).map((key, index) => {
			if (entryData[key].initial_value != entryData[key].value) {
				to_update.push({ locale: key, content: entryData[key].value });
			}
		});

		for (let index = 0; index < to_update.length; index++) {
			const update = to_update[index];
			const updated = await axios
				.patch(`translation/${entry.id}/?locale=${update.locale}`, {
					directory: translations[params.directory].title,
					content: update.content,
				})
				.then((e) => e.data)
				.catch((e) => null);
		}

		fetchTranslations().then((e) => {
			setEntryData(
				generate_dict_from_locale(
					locales,
					e.payload[parseInt(params.directory)].entries[
						[parseInt(params.entry)]
					]
				)
			);
		});
	};

	return (
		<div id="entry-item">
			<div className="entry-content">
				<h1>{entry.name}</h1>
				<p>{entry.description}</p>
				{Object.keys(entryData).map((key, index) => (
					<div key={index} className="input-container">
						<div className="input-header">
							<span>Content | {key} </span>
							{entryData[key].empty &&
							entryData[key].value == "" ? (
								<span className="missing">missing</span>
							) : (
								<span></span>
							)}
						</div>
						<textarea
							rows={5}
							onChange={(e) =>
								setEntryData({
									...entryData,
									[key]: {
										...entryData[key],
										value: e.target.value,
									},
								})
							}
							value={entryData[key].value}
						/>
					</div>
				))}
			</div>
			<div className="save-footer">
				<Button
					onClick={() =>
						setEntryData(generate_dict_from_locale(locales, entry))
					}
					variant="outlined"
					color="info"
				>
					cancel
				</Button>
				<Button
					onClick={save}
					disabled={!is_diff(entryData)}
					variant="contained"
				>
					Save
				</Button>
			</div>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(EntryItem);
