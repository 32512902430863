import React, { useContext, useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios from "../services/axios";
import AuthContext from "./AuthContext";
import { useSearchParams } from "react-router-dom";

const MediaContext = createContext();

export const MediaProvider = ({ children, media_id, locales }) => {
	const [media, setMedia] = useState(null);
	const [defaultMedia, setDefaultMedia] = useState(null);
	const [updatableMedia, setUpdatableMedia] = useState(null);
	const [currentLocale, setCurrentLocale] = useState(null);
	const [loading, setLoading] = useState(false);
	const updatableMediaBase = useRef(null);
	const can_save = useRef(false);
	const new_file = useRef(null);
	const [searchParams, setSearchParams] = useSearchParams();

	const load_media = async () => {
		const media = await axios
			.get(`media/${media_id}/`)
			.then((e) => e.data)
			.catch((e) => null);
		if (media) {
			setMedia(media);
			const default_media = media.medias.filter(
				(e) => e.id == media.default
			)[0];
			const updated = { ...default_media, file_name: null };
			can_save.current = false;
			setDefaultMedia(updated);
			const default_locale = locales[0].locale;
			const current_locale_code = currentLocale
				? currentLocale.locale
				: searchParams.get("locale")
				? searchParams.get("locale")
				: default_locale;
			let current = media.medias.filter(
				(e) => e.locale.locale == current_locale_code
			)[0];
			setUpdatableMedia(current);
			updatableMediaBase.current = JSON.stringify(current);
			new_file.current = null;
			if (!currentLocale || current_locale_code != currentLocale.locale) {
				const current_locale = locales.filter(
					(e) => e.locale == current_locale_code
				)[0];
				setCurrentLocale(current_locale);
			}
		}
	};

	const updateCurrent = (key, data) => {
		const copy = { ...updatableMedia, [key]: data };
		if (key == "file" && data == null) {
			copy.file_name = null;
		}
		can_save.current = JSON.stringify(copy) != updatableMediaBase.current;
		setUpdatableMedia(copy);
	};

	const update_locale = (code) => {
		const current_locale = locales.filter((e) => e.locale == code)[0];
		setCurrentLocale(current_locale);
		let current = media.medias.filter((e) => e.locale.locale == code);
		if (!current.length) {
			current = {
				name: "",
				description: "",
				file: null,
				file_name: null,
				locale: { ...current_locale },
			};
		} else {
			current = { ...current[0], file_name: null };
		}
		setUpdatableMedia(current);
		setSearchParams({ locale: current_locale.locale });
		updatableMediaBase.current = JSON.stringify(current);
	};

	const save = async () => {
		const data = new FormData();
		data.append("name", updatableMedia.name ? updatableMedia.name : "");
		data.append(
			"description",
			updatableMedia.description ? updatableMedia.description : ""
		);
		if (new_file.current) {
			data.append("file", new_file.current[0], new_file.current[0].name);
		} else if (updatableMedia.file == null) {
			data.append("empty_file", "true");
		}
		setLoading(true);
		const ret = await axios
			.patch(
				`media/${defaultMedia.id}/?locale=${updatableMedia.locale.locale}`,
				data,
				{ headers: { "Content-Type": "multipart/form-data" } }
			)
			.then((e) => e.data)
			.catch((e) => null);
		setLoading(false);
		if (ret) {
			load_media();
		}
	};

	useEffect(() => {
		if (media_id == "new") {
		} else {
			load_media();
		}
	}, [media_id]);

	useEffect(() => {
		if (locales.length) {
			setCurrentLocale(locales[0]);
		}
	}, [locales]);

	return (
		<MediaContext.Provider
			value={{
				media,
				new_file,
				defaultMedia,
				updatableMedia,
				updateCurrent,
				update_locale,
				currentLocale,
				loading,
				save,
				can_save,
			}}
		>
			{children}
		</MediaContext.Provider>
	);
};

export default MediaContext;
