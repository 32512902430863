import React from "react";
import "./style.scss";
import CheckIcon from "@mui/icons-material/Check";

const TableContent = ({
	data,
	columns,
	selection = [],
	select = () => null,
	onClick,
	can_select,
}) => {
	const selected_count = data.filter((e) =>
		selection.includes(e.content_table_id)
	).length;

	return (
		<div className="content-table-content keep-bar">
			<table>
				<thead>
					<tr>
						{can_select && (
							<td className="column-header">
								<button
									onClick={() =>
										select(
											"all",
											selected_count,
											data.length
										)
									}
									className={`checkbox ${
										selected_count == data.length &&
										data.length > 0
											? "selected"
											: selected_count != 0
											? "part-selected"
											: ""
									}`}
									role="checkbox"
								>
									<CheckIcon />
								</button>
							</td>
						)}
						{columns.map((column, id) => (
							<td key={id}>
								<div className="column-header">
									{column.headerName}
								</div>
							</td>
						))}
					</tr>
				</thead>
				<tbody>
					{data.map((line, id) => (
						<tr
							key={id}
							className={
								selection.includes(line.content_table_id)
									? "selected"
									: ""
							}
						>
							{can_select && (
								<td
									onClick={() =>
										select(line.content_table_id)
									}
									className="table-item check"
								>
									<button
										className={`checkbox ${
											selection.includes(
												line.content_table_id
											)
												? "selected"
												: ""
										}`}
									>
										<CheckIcon />
									</button>
								</td>
							)}
							{columns.map((column, c_id) => (
								<td
									key={c_id}
									className={onClick ? "clickable" : ""}
									onClick={() =>
										onClick ? onClick(line) : null
									}
								>
									{column.renderCell != null ? (
										column.renderCell({
											value: line[column.field],
											row: line,
										})
									) : (
										<div className="table-item">
											{line[column.field]}
										</div>
									)}
								</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default TableContent;
