import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Modal, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";

const NewModal = ({ open, entries, directories, fetchTranslations }) => {
	const [name, setName] = useState("");
	const [formData, setFormData] = useState({
		name: "",
		description: "",
		value: "",
	});
	const { directory } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		setFormData({
			name: "",
			description: "",
			value: "",
		});
	}, [open]);

	const create = async () => {
		console.log(directories[directory].entries.length);
		const created = await axios
			.post(`/translation/`, {
				directory: directories[directory].title,
				name: formData.name,
				description: formData.description,
				content: formData.value,
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (created) {
			fetchTranslations().then((e) =>
				navigate(
					`/translations/${directory}/${directories[directory].entries.length}`
				)
			);
		}
	};

	const exist = entries.filter((e) => e.name == formData.name);

	return (
		<Modal className="new-modal" open={open}>
			<div className="modal-center">
				<div className="modal-header">
					<span className="title">New translation</span>
				</div>
				<div className="modal-content">
					<TextField
						value={formData.name}
						autoFocus
						size="small"
						error={exist.length != 0}
						label="Translation key *"
						onChange={(e) =>
							setFormData({ ...formData, name: e.target.value })
						}
					/>
					<TextField
						fullWidth
						value={formData.description}
						multiline
						size="small"
						label="Description"
						onChange={(e) =>
							setFormData({
								...formData,
								description: e.target.value,
							})
						}
					/>
					<TextField
						fullWidth
						value={formData.value}
						size="small"
						label="Content *"
						onKeyDown={(e) => {
							if (
								e.key == "Enter" &&
								formData.name != "" &&
								formData.value != "" &&
								exist.length == 0
							) {
								create();
							}
						}}
						onChange={(e) =>
							setFormData({ ...formData, value: e.target.value })
						}
					/>
				</div>
				<div className="modal-footer">
					<Button
						onClick={() => navigate(`/translations/${directory}`)}
						variant="outlined"
						color="info"
					>
						cancel
					</Button>
					<Button
						disabled={
							formData.name == "" ||
							formData.value == "" ||
							exist.length != 0
						}
						variant="contained"
						color="primary"
						onClick={create}
					>
						Create
					</Button>
				</div>
			</div>
		</Modal>
	);
};

export default NewModal;
