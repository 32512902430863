import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchTranslations = createAsyncThunk(
	"translations/fetchTranslations",
	async (user_id) => {
		const ret = await axios.get(`translation/`);
		return ret.data;
	}
);

const translationsSlice = createSlice({
	name: "translations",
	initialState: [],
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchTranslations.fulfilled, (state, { payload }) => {
			return payload;
		});
	},
});

export const translationsMethods = {
	fetchTranslations,
};

export default translationsSlice;
