import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useParams } from "react-router-dom";
import axios from "../../../services/axios";
import ContentItemTopbar from "../../../components/content/content_item/content_item_topbar/ContentItemTopbar";
import ContentItemAside from "../../../components/content/content_item/content_item_aside/ContentItemAside";
import { ContentItemProvider } from "../../../contexts/ContentItemContext";
import AuthContext from "../../../contexts/AuthContext";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import ContentItemForm from "../../../components/content/content_item/content_item_form/ContentItemForm";

const ContentItem = ({ content, fetchContents }) => {
	const { model_id, entry_id } = useParams();
	const { locales, updatableItem } = useContext(AuthContext);

	useEffect(() => {
		if (model_id != undefined && content.model != model_id) {
			fetchContents(model_id);
		}
	}, [model_id]);

	return (
		<div id="content-item">
			<ContentItemProvider
				locales={locales}
				model_id={model_id}
				entry_id={entry_id}
			>
				<ContentItemTopbar />
				<ContentItemAside />
				<ContentItemForm />
			</ContentItemProvider>
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentItem);
