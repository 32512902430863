import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const ContentRichTextField = ({ label, value, onChange, locale }) => {
	const specific_locale = useRef("");
	const [reload, setReload] = useState(false);

	useEffect(() => {
		specific_locale.current = locale;
		setReload(!reload);
	}, [locale]);

	if (locale != specific_locale.current) {
		return null;
	}

	return (
		<div className="ck-editor-container">
			<CKEditor
				config={{
					toolbar: {
						items: [
							"heading",
							"|",
							"bold",
							"italic",
							"link",
							"bulletedList",
							"numberedList",
							"|",
							"alignment",
							"|",
							"indent",
							"outdent",
							"|",
							"blockQuote",
							"insertTable",
							"|",
							"undo",
							"redo",
						],
					},
				}}
				editor={ClassicEditor}
				data={value}
				onReady={(editor) => {
					// You can store the "editor" and use when it is needed.
					console.log("Editor is ready to use!", editor);
				}}
				onChange={(event, editor) => {
					const data = editor.getData();
					onChange(data);
				}}
			/>
		</div>
	);
};

export default ContentRichTextField;
