import { translationsMethods } from "./slices/translations";
import { mediasMethods } from "./slices/medias";
import { contentsMethods } from "./slices/content";

export const mapDispatchToProps = {
	...translationsMethods,
	...mediasMethods,
	...contentsMethods,
};

export const mapStateToProps = (state) => state;
