// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-item-form {
  padding: 40px;
  padding-top: calc(var(--navbar-height) + 70px + 40px);
  padding-left: 300px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.content-item-form.empty {
  gap: 20px;
}
.content-item-form .content-input-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  width: 100%;
  max-width: 600px;
  position: relative;
}
.content-item-form .content-input-block::before {
  content: "";
  height: 100%;
  width: 2px;
  background-color: var(--clr-divider);
  display: block;
  position: absolute;
  left: -15px;
  top: 0;
}
.content-item-form .content-input-block .input-block-title {
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  font-family: var(--title-family);
}
.content-item-form .content-input-block .input-block-title span {
  font-weight: normal;
  font-size: 0.9rem;
}`, "",{"version":3,"sources":["webpack://./src/components/content/content_item/content_item_form/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,qDAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,WAAA;EACA,mBAAA;AACD;AACC;EACC,SAAA;AACF;AAEC;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;EACA,kBAAA;AAAF;AAEE;EACC,WAAA;EACA,YAAA;EACA,UAAA;EACA,oCAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;AAAH;AAGE;EACC,cAAA;EACA,mBAAA;EACA,iBAAA;EACA,gCAAA;AADH;AAGG;EACC,mBAAA;EACA,iBAAA;AADJ","sourcesContent":[".content-item-form {\n\tpadding: 40px;\n\tpadding-top: calc(var(--navbar-height) + 70px + 40px);\n\tpadding-left: 300px;\n\tdisplay: flex;\n\tflex-direction: column;\n\twidth: 100%;\n\talign-items: center;\n\n\t&.empty {\n\t\tgap: 20px;\n\t}\n\n\t.content-input-block {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tmargin-bottom: 40px;\n\t\twidth: 100%;\n\t\tmax-width: 600px;\n\t\tposition: relative;\n\n\t\t&::before {\n\t\t\tcontent: \"\";\n\t\t\theight: 100%;\n\t\t\twidth: 2px;\n\t\t\tbackground-color: var(--clr-divider);\n\t\t\tdisplay: block;\n\t\t\tposition: absolute;\n\t\t\tleft: -15px;\n\t\t\ttop: 0;\n\t\t}\n\n\t\t.input-block-title {\n\t\t\tdisplay: block;\n\t\t\tmargin-bottom: 20px;\n\t\t\tfont-weight: bold;\n\t\t\tfont-family: var(--title-family);\n\n\t\t\tspan {\n\t\t\t\tfont-weight: normal;\n\t\t\t\tfont-size: 0.9rem;\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
