import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import MediaContext from "../../../../contexts/MediaContext";
import { useTranslation } from "react-i18next";
import { Button, TextField, Tooltip } from "@mui/material";
import MediaViewer from "../media_viewer/MediaViewer";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { baseURL } from "../../../../services/axios";
import { Link } from "react-router-dom";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const MediaItemForm = () => {
	const { media, currentLocale, updatableMedia, updateCurrent, new_file } =
		useContext(MediaContext);
	const { t } = useTranslation("locales");
	const [reload, setReload] = useState(false);
	const main_file = useRef(null);
	const input_ref = useRef(null);

	const change_file = () => {
		const input = document.getElementById("f-input-main-file");
		if (input.files.length) {
			const reader = new FileReader();
			reader.onload = function (event) {
				main_file.current = [input.files[0], event.target.result];
				new_file.current = [input.files[0], event.target.result];
				updateCurrent("file_name", input.files[0].name);
				setReload(!reload);
			};
			reader.readAsDataURL(input.files[0]);
		}
	};

	useEffect(() => {
		main_file.current = null;
		setReload(!reload);
	}, [media]);

	if (!updatableMedia) return null;

	return (
		<div className="media-item-form">
			<div className="media-input-block">
				<span className="input-block-title">
					Title
					<span> | {t(currentLocale?.locale)}</span>
				</span>
				<TextField
					size="small"
					placeholder="Title"
					value={updatableMedia?.name ? updatableMedia.name : ""}
					onChange={(e) =>
						updateCurrent(
							"name",
							e.target.value == "" ? null : e.target.value
						)
					}
				/>
			</div>
			<div className="media-input-block">
				<span className="input-block-title">
					Description
					<span> | {t(currentLocale?.locale)}</span>
				</span>
				<TextField
					size="small"
					placeholder={"Description"}
					multiline
					value={
						updatableMedia?.description
							? updatableMedia.description
							: ""
					}
					onChange={(e) =>
						updateCurrent(
							"description",
							e.target.value == "" ? null : e.target.value
						)
					}
				/>
			</div>
			<div className="media-input-block">
				<span className="input-block-title">
					File
					<span> | {t(currentLocale?.locale)}</span>
				</span>
				<div className="file-input-container">
					{main_file.current == null && !updatableMedia?.file ? (
						<div className="file-input">
							<input
								ref={input_ref}
								onChange={() => change_file()}
								id="f-input-main-file"
								type="file"
							/>
							<Button
								variant="outlined"
								onClick={() => input_ref.current.click()}
							>
								Upload file
							</Button>
						</div>
					) : (
						<>
							<MediaViewer
								name={
									main_file.current
										? main_file.current[0].name
										: updatableMedia.file
								}
								content={
									main_file.current
										? main_file.current[1]
										: `${baseURL}${updatableMedia.file}`
								}
							/>
							<div className="actions">
								<Tooltip title="Delete">
									<div
										onClick={() => {
											main_file.current = null;
											new_file.current = null;
											updateCurrent("file", null);
											setReload(!reload);
										}}
										className="action-item"
									>
										<DeleteIcon />
									</div>
								</Tooltip>
								{!main_file.current && (
									<Tooltip title="Download">
										<Link
											target="_blank"
											to={`${baseURL}${updatableMedia.file}`}
											className="action-item"
										>
											<DownloadIcon />
										</Link>
									</Tooltip>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default MediaItemForm;
