import React, { useContext } from "react";
import { MediaProvider } from "../../../contexts/MediaContext";
import { useParams } from "react-router-dom";
import MediaItemTopbar from "../../../components/media/media_item/media_item_topbar/MediaItemTopbar";
import MediaItemAside from "../../../components/media/media_item/media_item_aside/MediaItemAside";
import MediaItemForm from "../../../components/media/media_item/media_item_form/MediaItemForm";
import AuthContext from "../../../contexts/AuthContext";

const MediaItem = () => {
	const { media_id } = useParams();
	const { locales } = useContext(AuthContext);

	return (
		<div id="media-item">
			<MediaProvider media_id={media_id} locales={locales}>
				<MediaItemTopbar />
				<MediaItemAside />
				<MediaItemForm />
			</MediaProvider>
		</div>
	);
};

export default MediaItem;
