import React, { useContext } from "react";
import "./style.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import { Button } from "@mui/material";

const EntriesNav = ({ entries }) => {
	const { directory, entry } = useParams();
	const { locales } = useContext(AuthContext);
	const navigate = useNavigate();

	return (
		<ul id="entries-nav" className={entry == undefined ? "large" : ""}>
			<li style={{ marginBottom: "10px" }}>
				<Button
					fullWidth={entry == undefined ? false : true}
					variant="outlined"
					size="small"
					onClick={() => navigate(`/translations/${directory}/new`)}
				>
					New entry
				</Button>
			</li>
			{entries.map((entr, index) => (
				<li
					className={
						entry == index ? "selected list-link" : "list-link"
					}
					key={index}
				>
					<Link to={`/translations/${directory}/${index}`}>
						<div className="entry-data-content">
							<div className="entry-nav-item-header">
								<span className="field-name">{entr.name}</span>
								{locales.length > entr.entries?.length + 1 ? (
									<span className="missing">missing</span>
								) : (
									<span></span>
								)}
							</div>
							<div className="locales-container">
								<span>{entr.locale.locale}</span>
								{entr.entries.map((inner_entry, index) => (
									<span key={index}>
										{inner_entry.locale.locale}
									</span>
								))}
							</div>
						</div>
						{entry == undefined && (
							<div className="desc-container">
								{entr.description}
							</div>
						)}
					</Link>
				</li>
			))}
		</ul>
	);
};

export default EntriesNav;
