import { configureStore } from "@reduxjs/toolkit";
import translationsSlice from "./slices/translations";
import mediasSlice from "./slices/medias";
import contentsSlice from "./slices/content";

export const store = configureStore({
	reducer: {
		translations: translationsSlice.reducer,
		medias: mediasSlice.reducer,
		content: contentsSlice.reducer,
	},
});
