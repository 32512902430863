import { Alert, Button, Snackbar, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import "./confirmation-context.scss";
import { TOAST_LVL } from "../constants/constants";
import { LoadingButton } from "@mui/lab";

const ConfirmationContext = createContext();

let type_value = "";

export const ConfirmationProvider = (props) => {
	const set_confirm_ref = useRef(() => null);

	const setToast = useRef(() => null);

	const open_confirm = (title, body = "", confirm_value = "", callback) => {
		set_confirm_ref.current({
			value: confirm_value,
			title: title,
			body: body,
			is_open: true,
			callback: callback,
		});
		document.body.style.overflow = "hidden";
	};

	const close_confirm = () => {
		set_confirm_ref.current({ value: "", is_open: false });
		document.body.style.overflow = "hidden";
		type_value = "";
	};

	const Confirm = () => {
		const [confirm, setConfirm] = useState({
			value: "test",
			title: "Delete text message",
			body: "This action cannot be undone. This will permanently delete the selected messages.",
			is_open: false,
			callback: () => null,
		});
		const [reload, setReload] = useState(false);
		const [loading, setLoading] = useState(false);

		useEffect(() => {
			set_confirm_ref.current = setConfirm;
		}, []);

		if (!confirm.is_open) return null;
		return (
			<div className="confirm-view">
				<div className="confirm-view-center">
					<div className="confirm-view-header">
						<h3>{confirm.title}</h3>
						<p>{confirm.body}</p>
					</div>
					<div className="confirm-view-body">
						{confirm.value != "" ? (
							<>
								<p>
									Please type{" "}
									<span className="bold">
										{confirm.value}
									</span>{" "}
									to confirm
								</p>
								<TextField
									size="small"
									defaultValue={type_value}
									onChange={(e) => {
										type_value = e.target.value;
										if (type_value == confirm.value)
											setReload(!reload);
									}}
									fullWidth
									className="value-input"
									id="outlined-basic"
									label={confirm.value}
									variant="outlined"
								/>
							</>
						) : null}
					</div>
					<div className="confirm-view-footer">
						<Button onClick={close_confirm} variant="outlined">
							Cancel
						</Button>
						<LoadingButton
							disabled={type_value != confirm.value}
							loading={loading}
							onClick={async () => {
								setLoading(true);
								await confirm.callback();
								setLoading(false);
								close_confirm();
							}}
							variant="contained"
						>
							Confirm
						</LoadingButton>
					</div>
				</div>
			</div>
		);
	};

	const Toast = () => {
		const [toastData, setToastData] = useState(null);

		useEffect(() => {
			setToast.current = (level, title, description) => {
				setToastData({
					title: title,
					description: description,
					level: level,
				});
			};
		}, []);

		return (
			<Snackbar
				open={toastData != null}
				className="main-toast-snack"
				autoHideDuration={6000}
				onClose={() => setToastData(null)}
			>
				<Alert
					onClose={() => setToastData(null)}
					severity={
						toastData?.level == TOAST_LVL.ERROR
							? "error"
							: "success"
					}
					sx={{ width: "100%" }}
				>
					<div className="toast-message-container">
						<span className="title">{toastData?.title}</span>
						<span>{toastData?.description}</span>
					</div>
				</Alert>
			</Snackbar>
		);
	};

	return (
		<ConfirmationContext.Provider
			value={{
				open_confirm,
				close_confirm,
				setToast,
			}}
		>
			<Toast />
			<Confirm />
			{props.children}
		</ConfirmationContext.Provider>
	);
};

export default ConfirmationContext;
