import React, { useContext } from "react";
import "./style.scss";
import AuthContext from "../../../contexts/AuthContext";
import { Link, useParams } from "react-router-dom";

const ContentNav = () => {
	const { models } = useContext(AuthContext);
	const { model_id } = useParams();

	return (
		<div id="content-navbar">
			<div className="navbar-block">
				<span className="block-title">Content type</span>
				<ul>
					{models.map((model, index) => (
						<li
							className={
								model_id == model.model_id
									? "selected list-link"
									: "list-link"
							}
							key={index}
						>
							<Link to={`/content/${model.model_id}`}>
								<span>{model.name}</span>
							</Link>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ContentNav;
