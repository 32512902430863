// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-item-topbar {
  position: fixed;
  top: var(--navbar-height);
  left: 0;
  width: 100%;
  background-color: #f0f0f0;
  border-bottom: 1px solid var(--clr-divider);
  padding: 0 20px;
  height: 70px;
  display: flex;
  align-items: center;
  z-index: 1;
}
.content-item-topbar .surface-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
}
.content-item-topbar .surface-hover:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.content-item-topbar .title-type-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
}
.content-item-topbar .title-type-container .model {
  font-size: 14px;
  font-weight: bold;
  color: var(--clr-secondary-text);
}
.content-item-topbar .title-type-container .title {
  font-weight: bold;
  font-size: 16px;
}
.content-item-topbar .title-type-container .title .locale {
  font-size: 14px;
  color: var(--clr-secondary-text);
}`, "",{"version":3,"sources":["webpack://./src/components/content/content_item/content_item_topbar/style.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,yBAAA;EACA,OAAA;EACA,WAAA;EACA,yBAAA;EACA,2CAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,UAAA;AACD;AACC;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AACF;AAAE;EACC,qCAAA;AAEH;AAEC;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;AAAF;AAEE;EACC,eAAA;EACA,iBAAA;EACA,gCAAA;AAAH;AAGE;EACC,iBAAA;EACA,eAAA;AADH;AAGG;EACC,eAAA;EACA,gCAAA;AADJ","sourcesContent":[".content-item-topbar {\n\tposition: fixed;\n\ttop: var(--navbar-height);\n\tleft: 0;\n\twidth: 100%;\n\tbackground-color: #f0f0f0;\n\tborder-bottom: 1px solid var(--clr-divider);\n\tpadding: 0 20px;\n\theight: 70px;\n\tdisplay: flex;\n\talign-items: center;\n\tz-index: 1;\n\n\t.surface-hover {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tpadding: 6px;\n\t\tborder-radius: 10px;\n\t\ttransition: 0.3s;\n\t\tcursor: pointer;\n\t\t&:hover {\n\t\t\tbackground-color: rgba($color: #000000, $alpha: 0.05);\n\t\t}\n\t}\n\n\t.title-type-container {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: flex-start;\n\t\tmargin-left: 20px;\n\n\t\t.model {\n\t\t\tfont-size: 14px;\n\t\t\tfont-weight: bold;\n\t\t\tcolor: var(--clr-secondary-text);\n\t\t}\n\n\t\t.title {\n\t\t\tfont-weight: bold;\n\t\t\tfont-size: 16px;\n\n\t\t\t.locale {\n\t\t\t\tfont-size: 14px;\n\t\t\t\tcolor: var(--clr-secondary-text);\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
