import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../services/axios";
import ContentTable from "../../table/table/ContentTable";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../store/dispatcher";
import loadingContent from "../../../assets/animations/loading-content.json";
import Lottie from "lottie-react";
import AuthContext from "../../../contexts/AuthContext";
import { Delete } from "@mui/icons-material";

const columns = [
	{
		width: 120,
		headerName: "ID",
		field: "id",
	},
	{
		width: 120,
		headerName: "Title",
		field: "title",
	},
	{
		width: 120,
		headerName: "Content Type",
		field: "type",
	},
	{
		width: 120,
		headerName: "Status",
		field: "status",
	},
];

const ContentList = ({ content }) => {
	const { model_id } = useParams();
	const [modelItems, setModelItems] = useState([]);
	const { models } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (content.model == model_id) {
			const new_lst = [];
			for (let index = 0; index < content.entries.length; index++) {
				const item = content.entries[index];
				new_lst.push({
					id: item.id,
					type: item.type,
					title: item.title ? item.title : "Unnamed",
					status: item.status,
				});
			}
			setModelItems(new_lst);
		}
	}, [content]);

	if (content.model != model_id) {
		return (
			<div id="content-list" className="empty">
				<Lottie animationData={loadingContent} loop={true} />
			</div>
		);
	}

	return (
		<div id="content-list">
			<ContentTable
				create={() => navigate(`/content/${model_id}/new`)}
				onClick={(e) => navigate(`/content/${model_id}/${e.id}`)}
				columns={columns}
				pageSizeOptions={[10, 20, 50, 100]}
				title={models.filter((m) => m.model_id == model_id)[0].name}
				data={JSON.parse(JSON.stringify(modelItems))}
				can_select={true}
				actions={[
					{
						title: "Delete",
						icon: <Delete />,
						action: (e) => console.log("delete", e),
					},
				]}
			/>
		</div>
	);
};

export default connect(mapStateToProps, null)(ContentList);
