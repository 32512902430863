import React, { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios, {
	baseURL,
	set_instance_token,
	unset_instance_token,
} from "../services/axios";
import axios_auth from "../services/axios_auth";
import { getSpecificData, storeData } from "../helpers/store_data";

const AuthContext = createContext();

const default_user = {
	first_name: "Anonymous",
	last_name: "User",
	token: null,
	is_login: false,
};

export const AuthProvider = (props) => {
	const [user, setUser] = useState({ ...default_user });
	const [locales, setLocales] = useState([]);
	const [models, setModels] = useState([]);

	const profile = async (token) => {
		const local = await axios
			.get("/locales/")
			.then((e) => e.data)
			.catch((e) => []);
		setLocales(local);
		const model = await axios
			.get("/models/")
			.then((e) => e.data)
			.catch((e) => {
				console.log(e);
				return [];
			});
		setModels(model);
		setUser({
			first_name: "Anonymous",
			last_name: "User",
			token: token,
			is_login: token && token.length > 10 ? true : false,
		});
	};

	const login = async (email, password) => {
		let error = "";
		const res = await axios_auth
			.post(`/users/authenticate`, {
				username: email,
				password: password,
			})
			.then((e) => e.data)
			.catch((e) => {
				error = e.response?.data?.message;
				return null;
			});
		if (!res) {
			logout();
			return error;
		} else {
			storeData("token", res.token);
			set_instance_token(res.token);
			await profile(res.token);
			return true;
		}
	};

	const setup = async () => {
		const token = getSpecificData("token");
		// storeData("token", "a9e728129fb144b88bc1c14b0b2f00790c8b6ed3");
		if (token) {
			set_instance_token(token);
			await profile(token);
		} else {
			localStorage.clear();
		}
	};

	useEffect(() => {
		setup();
	}, []);

	const logout = async () => {
		localStorage.clear();
		unset_instance_token();
		setUser({ ...default_user });
	};

	if (!user.token) {
		return "hello je load";
	}

	return (
		<AuthContext.Provider
			value={{
				user,
				locales,
				models,
				login,
				logout,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
