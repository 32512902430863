// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#content-list {
  padding: 30px;
  padding-top: calc(var(--navbar-height) + 30px);
  padding-left: 270px;
}
#content-list.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100svh;
}
#content-list.empty > div {
  max-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/components/content/content_table/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8CAAA;EACA,mBAAA;AACD;AACC;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;AACF;AACE;EACC,gBAAA;AACH","sourcesContent":["#content-list {\n\tpadding: 30px;\n\tpadding-top: calc(var(--navbar-height) + 30px);\n\tpadding-left: 270px;\n\n\t&.empty {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\theight: 100svh;\n\n\t\t> div {\n\t\t\tmax-width: 400px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
