import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";

const NavbarItem = ({ location, title }) => {
	const loc = useLocation();
	return (
		<li className={loc.pathname.startsWith(location) ? "selected" : ""}>
			<Link to={location}>{title}</Link>
		</li>
	);
};

const Navbar = () => {
	const { t } = useTranslation();

	return (
		<nav id="main-navbar">
			<ul>
				<NavbarItem title={t("content")} location={"/content"} />
				<NavbarItem title={t("media")} location={"/media"} />
				<NavbarItem
					title={t("translations")}
					location={"/translations"}
				/>
			</ul>
		</nav>
	);
};

export default Navbar;
