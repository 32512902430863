import axios from "axios";
import { baseURL } from "./axios";

const instance = axios.create({
	baseURL: `${baseURL}/auth/`,
	headers: {
		"Content-Type": "application/json",
	},
	timeout: 10000,
});

export default instance;
