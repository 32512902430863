import React from "react";
import "./style.scss";
import { baseURL } from "../../../services/axios";

export const image_meme = ["png", "jpg", "webp", "svg"];

const RenderMedia = ({ media }) => {
	const file_url = media?.file ? media.file : "base.empty";
	const end_url = file_url.split(".")[file_url.split(".").length - 1];

	if (image_meme.includes(end_url)) {
		return (
			<div className="media-item-container">
				<img src={`${baseURL}${media.file}`} alt={media.title} />
			</div>
		);
	}
	return (
		<div className="media-item-container">
			<div className="non-picture">
				<span>{end_url}</span>
			</div>
		</div>
	);
};

export default RenderMedia;
