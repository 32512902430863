import React, { useEffect, useState } from "react";
import "./style.scss";
import { Button, Modal, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "../../../../../services/axios";
import { connect } from "react-redux";
import { mapDispatchToProps } from "../../../../../store/dispatcher";
import { LoadingButton } from "@mui/lab";

const NamingModal = ({
	model,
	open,
	fetchContents,
	fetchMedias,
	is_media = false,
}) => {
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const create = async () => {
		setLoading(true);
		if (is_media) {
			const data = new FormData();
			data.append("name", name);
			const created = await axios
				.post(`media/`, data, {
					headers: { "Content-Type": "multipart/form-data" },
				})
				.then((e) => e.data)
				.catch((e) => null);

			if (created) {
				fetchMedias();
				navigate(`/media/${created.id}`);
			}
		} else {
			const created = await axios
				.post(`/content/${model.model_id}/`, {
					"@title": name,
				})
				.then((e) => e.data)
				.catch((e) => null);

			if (created) {
				fetchContents(model.model_id);
				navigate(`/content/${model.model_id}/${created.id}`);
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		setName("");
	}, [open]);

	if (!model) return null;

	return (
		<Modal className="naming-modal" open={open}>
			<div className="modal-center">
				<div className="modal-header">
					{is_media ? (
						<>
							<span className="title">Name the new media</span>
							<p>
								The name will not be directly visible for
								classic users, it is just used to help you
								identify the media
							</p>
						</>
					) : (
						<>
							<span className="title">
								Name the new {model.name}
							</span>
							<p>
								The name will not be directly visible for
								classic users, it is just used to help you
								identify the entry
							</p>
						</>
					)}
				</div>
				<div className="modal-content">
					<TextField
						fullWidth
						value={name}
						size="small"
						label="Type the name"
						onChange={(e) => setName(e.target.value)}
					/>
				</div>
				<div className="modal-footer">
					<Button
						onClick={() =>
							is_media
								? navigate(`/media`)
								: navigate(`/content/${model.model_id}`)
						}
						variant="outlined"
						color="info"
						disabled={loading}
					>
						cancel
					</Button>
					<LoadingButton
						loading={loading}
						disabled={name == ""}
						variant="contained"
						color="primary"
						onClick={create}
					>
						Create
					</LoadingButton>
				</div>
			</div>
		</Modal>
	);
};

export default connect(null, mapDispatchToProps)(NamingModal);
