import React, { useEffect, useState } from "react";
import "./style.scss";
import {
	Button,
	ClickAwayListener,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	MenuList,
	Paper,
	Popper,
} from "@mui/material";

const TableHeader = ({
	title,
	columns,
	full_data,
	create,
	selection,
	actions,
	clear_selection,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		document.addEventListener("scroll", handleClose);

		return () => {
			document.removeEventListener("scroll", handleClose);
		};
	}, []);

	return (
		<div className="content-table-header">
			<h1>{title}</h1>
			<div className="table-header-filters-container">
				{selection.length ? (
					<>
						<Button
							onClick={handleClick}
							size="small"
							variant="outlined"
						>
							Action on selection
						</Button>
						{actions != null && (
							<Popper
								style={{ zIndex: 100 }}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								placement="bottom-end"
							>
								<Paper>
									<ClickAwayListener
										onClickAway={handleClose}
									>
										<MenuList>
											{actions.map((action, index) => (
												<MenuItem
													onClick={() => {
														action.action(
															full_data.filter(
																(elem, id) =>
																	selection.includes(
																		id
																	)
															)
														);
														clear_selection();
														handleClose();
													}}
													key={index}
												>
													<ListItemIcon>
														{action.icon}
													</ListItemIcon>
													<ListItemText>
														{action.title}
													</ListItemText>
												</MenuItem>
											))}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Popper>
						)}
					</>
				) : (
					<Button onClick={create} size="small" variant="outlined">
						New
					</Button>
				)}
			</div>
		</div>
	);
};

export default TableHeader;
