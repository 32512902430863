import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchMedias = createAsyncThunk("medias/fetchMedias", async () => {
	const ret = await axios.get(`media/`);
	return ret.data;
});

const mediasSlice = createSlice({
	name: "medias",
	initialState: [],
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchMedias.fulfilled, (state, { payload }) => {
			return payload;
		});
	},
});

export const mediasMethods = {
	fetchMedias,
};

export default mediasSlice;
