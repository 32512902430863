import React, { useContext, useEffect } from "react";
import ContentNav from "../../components/content/content_nav/ContentNav";
import ContentList from "../../components/content/content_table/ContentList";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import AuthContext from "../../contexts/AuthContext";

const Content = ({ content, fetchContents }) => {
	const { model_id } = useParams();
	const { models } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (model_id != undefined && content.model != model_id) {
			fetchContents(model_id);
		} else if (!model_id && models.length) {
			navigate(`/content/${models[0].model_id}`);
		}
	}, [model_id]);

	return (
		<div id="content-view">
			<ContentNav />
			{model_id != undefined && <ContentList />}
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
