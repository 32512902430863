// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#content-navbar {
  height: 100svh;
  position: fixed;
  left: 0;
  top: 0;
  padding: 20px;
  padding-top: calc(var(--navbar-height) + 20px);
  z-index: 1;
  width: 240px;
  border-right: 1px solid var(--clr-divider);
}
#content-navbar * {
  font-family: var(--title-family);
}
#content-navbar .navbar-block .block-title {
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}
#content-navbar .navbar-block ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
#content-navbar .navbar-block ul li a {
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/content/content_nav/style.scss"],"names":[],"mappings":"AAAA;EACC,cAAA;EACA,eAAA;EACA,OAAA;EACA,MAAA;EACA,aAAA;EACA,8CAAA;EACA,UAAA;EACA,YAAA;EACA,0CAAA;AACD;AACC;EACC,gCAAA;AACF;AAGE;EACC,mBAAA;EACA,cAAA;EACA,iBAAA;AADH;AAIE;EACC,aAAA;EACA,sBAAA;EACA,SAAA;AAFH;AAKI;EACC,2BAAA;AAHL","sourcesContent":["#content-navbar {\n\theight: 100svh;\n\tposition: fixed;\n\tleft: 0;\n\ttop: 0;\n\tpadding: 20px;\n\tpadding-top: calc(var(--navbar-height) + 20px);\n\tz-index: 1;\n\twidth: 240px;\n\tborder-right: 1px solid var(--clr-divider);\n\n\t* {\n\t\tfont-family: var(--title-family);\n\t}\n\n\t.navbar-block {\n\t\t.block-title {\n\t\t\tmargin-bottom: 10px;\n\t\t\tdisplay: block;\n\t\t\tfont-weight: bold;\n\t\t}\n\n\t\tul {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tgap: 10px;\n\n\t\t\tli {\n\t\t\t\ta {\n\t\t\t\t\tjustify-content: flex-start;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
