import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "../../services/axios";

export const fetchContents = createAsyncThunk(
	"contents/fetchContents",
	async (model_id) => {
		const ret = await axios.get(`content/${model_id}/`);
		return {
			model: model_id,
			entries: ret.data,
		};
	}
);

const contentsSlice = createSlice({
	name: "contents",
	initialState: {
		model: "",
		entries: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchContents.fulfilled, (state, { payload }) => {
			return payload;
		});
	},
});

export const contentsMethods = {
	fetchContents,
};

export default contentsSlice;
