// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-table-container .content-table-header {
  display: flex;
  flex-direction: column;
}
.content-table-container .content-table-header h1 {
  font-size: 1.8rem;
}
.content-table-container .content-table-header .table-header-filters-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/table/table_header/style.scss"],"names":[],"mappings":"AACC;EACC,aAAA;EACA,sBAAA;AAAF;AAEE;EACC,iBAAA;AAAH;AAGE;EACC,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;AADH","sourcesContent":[".content-table-container {\n\t.content-table-header {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\n\t\th1 {\n\t\t\tfont-size: 1.8rem;\n\t\t}\n\n\t\t.table-header-filters-container {\n\t\t\tdisplay: flex;\n\t\t\talign-items: center;\n\t\t\tjustify-content: flex-end;\n\t\t\tpadding: 10px 0;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
