import React, { useContext } from "react";
import "../../../content/content_item/content_item_topbar/style.scss";
import { Skeleton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import ContentItemContext from "../../../../contexts/ContentItemContext";
import { useTranslation } from "react-i18next";
import MediaContext from "../../../../contexts/MediaContext";
import NamingModal from "../../../content/content_item/content_item_form/naming_modal/NamingModal";

const MediaItemTopbar = () => {
	const { media, defaultMedia, currentLocale } = useContext(MediaContext);
	const { media_id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation("locales");

	if (!media) {
		return (
			<>
				<NamingModal
					open={media_id == "new"}
					model={"model"}
					is_media={true}
				/>
				<div className="content-item-topbar">
					<div
						onClick={() => navigate(`/media`)}
						className="surface-hover"
					>
						<ArrowBackIcon />
					</div>
					<Skeleton
						variant="rectangular"
						style={{ borderRadius: 5 }}
						width={120}
						height={20}
					/>
				</div>
			</>
		);
	}
	return (
		<div className="content-item-topbar">
			<div onClick={() => navigate(`/media`)} className="surface-hover">
				<ArrowBackIcon />
			</div>
			<div className="title-type-container">
				<span className="model">Asset</span>
				<span className="title">
					{defaultMedia.name ? defaultMedia.name : "Unnamed"}
					<span className="locale"> | {t(currentLocale.locale)}</span>
				</span>
			</div>
		</div>
	);
};

export default MediaItemTopbar;
