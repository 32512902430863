import React, { useEffect, useState } from "react";
import TableHeader from "../table_header/TableHeader";
import TableContent from "../table_content/TableContent";
import TableFooter from "../table_footer/TableFooter";

const ContentTable = ({
	title = "",
	data = [],
	columns = [],
	pageSizeOptions = [],
	onClick = null,
	create = null,
	can_select = false,
	actions = null,
}) => {
	const [reworkData, setReworkData] = useState({
		data: [],
		shown_data: [],
		rows_per_page: 10,
		current_page: 0,
		max_page: 0,
	});
	const [selection, setSelection] = useState([]);
	const [reworkColumns, setReworkColumns] = useState(columns);

	useEffect(() => {
		const new_data = [...data];
		new_data.forEach((line, index) => (line.content_table_id = index));
		const start_index = reworkData.current_page * reworkData.rows_per_page;
		const end_index =
			reworkData.current_page * reworkData.rows_per_page +
			reworkData.rows_per_page;
		const max_page =
			Math.floor(data.length / reworkData.rows_per_page) ==
			data.length / reworkData.rows_per_page
				? Math.floor(data.length / reworkData.rows_per_page)
				: Math.floor(data.length / reworkData.rows_per_page) + 1;

		setReworkData({
			data: data,
			shown_data: data.slice(start_index, end_index),
			rows_per_page: 10,
			current_page: 0,
			max_page: max_page,
		});
	}, [data]);

	useEffect(() => {
		setReworkColumns(columns);
	}, [columns]);

	const select_item = (item_id, count = 0, total = 0) => {
		const new_selection = [...selection];
		if (item_id == "all") {
			if (count == total) {
				for (
					let index = 0;
					index < reworkData.shown_data.length;
					index++
				) {
					const item = reworkData.shown_data[index];
					if (new_selection.includes(item.content_table_id)) {
						new_selection.splice(
							new_selection.indexOf(item.content_table_id),
							1
						);
					}
				}
			} else {
				for (
					let index = 0;
					index < reworkData.shown_data.length;
					index++
				) {
					const item = reworkData.shown_data[index];
					if (!new_selection.includes(item.content_table_id)) {
						new_selection.push(item.content_table_id);
					}
				}
			}
		} else {
			if (new_selection.includes(item_id)) {
				new_selection.splice(new_selection.indexOf(item_id), 1);
			} else {
				new_selection.push(item_id);
			}
		}

		setSelection(new_selection);
	};

	const set_row_per_page = (count) => {
		set_page(reworkData.current_page, count);
	};

	const set_page = (page, rows_per_page = reworkData.rows_per_page) => {
		if (page < 0 || page > reworkData.max_page - 1) return;
		const new_data = [...data];
		page =
			page == "last"
				? reworkData.max_page - 1
				: page == "first"
				? 0
				: page;

		const max_page =
			Math.floor(data.length / rows_per_page) ==
			data.length / rows_per_page
				? Math.floor(data.length / rows_per_page)
				: Math.floor(data.length / rows_per_page) + 1;

		page = page >= max_page ? max_page - 1 : page;

		const start_index = page * rows_per_page;
		const end_index = page * rows_per_page + rows_per_page;

		setReworkData({
			...reworkData,
			shown_data: new_data.slice(start_index, end_index),
			rows_per_page: rows_per_page,
			max_page: max_page,
			current_page: page,
		});
	};

	return (
		<div className="content-table-container">
			<TableHeader
				title={title}
				columns={reworkColumns}
				base_columns={columns}
				create={create}
				selection={selection}
				actions={actions}
				full_data={data}
				clear_selection={() => setSelection([])}
			/>
			<TableContent
				data={reworkData.shown_data}
				columns={reworkColumns}
				selection={selection}
				select={select_item}
				onClick={onClick}
				can_select={can_select}
			/>
			<TableFooter
				set_page={set_page}
				set_row_per_page={set_row_per_page}
				selection_size={selection.length}
				full_data={reworkData}
				page_size_options={pageSizeOptions}
			/>
		</div>
	);
};

export default ContentTable;
