// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#media {
  padding: 40px;
  padding-top: calc(var(--navbar-height) + 40px);
}
#media .media-content {
  padding-left: 160px;
}`, "",{"version":3,"sources":["webpack://./src/routes/media/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,8CAAA;AACD;AACC;EACC,mBAAA;AACF","sourcesContent":["#media {\n\tpadding: 40px;\n\tpadding-top: calc(var(--navbar-height) + 40px);\n\n\t.media-content {\n\t\tpadding-left: 160px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
