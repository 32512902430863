import React, { useContext } from "react";
import "./style.scss";
import { Skeleton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import ContentItemContext from "../../../../contexts/ContentItemContext";
import { useTranslation } from "react-i18next";

const ContentItemTopbar = () => {
	const { contentItem, model, currentLocale } =
		useContext(ContentItemContext);
	const navigate = useNavigate();
	const { t } = useTranslation("locales");

	if (!contentItem) {
		return (
			<div className="content-item-topbar">
				<div
					onClick={() => navigate(`/content/${model.model_id}`)}
					className="surface-hover"
				>
					<ArrowBackIcon />
				</div>
				<Skeleton
					variant="rectangular"
					style={{ borderRadius: 5 }}
					width={120}
					height={20}
				/>
			</div>
		);
	}
	return (
		<div className="content-item-topbar">
			<div
				onClick={() => navigate(`/content/${model.model_id}`)}
				className="surface-hover"
			>
				<ArrowBackIcon />
			</div>
			<div className="title-type-container">
				<span className="model">{model.name}</span>
				<span className="title">
					{contentItem.title ? contentItem.title : "Unnamed"}
					<span className="locale"> | {t(currentLocale.locale)}</span>
				</span>
			</div>
		</div>
	);
};

export default ContentItemTopbar;
