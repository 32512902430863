import React, { useContext, useEffect } from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Navbar from "./components/navigation/navbar/Navbar";
import Login from "./routes/auth/Login";
import AuthContext from "./contexts/AuthContext";
import Translations from "./routes/translations/Translations";
import Content from "./routes/content/Content";
import Media from "./routes/media/Media";
import { connect } from "react-redux";
import { mapDispatchToProps } from "./store/dispatcher";
import ContentItem from "./routes/content/content_item/ContentItem";
import "./i18n";
import MediaItem from "./routes/media/media_item/MediaItem";

const App = ({ fetchTranslations, fetchMedias }) => {
	const { user } = useContext(AuthContext);

	useEffect(() => {
		if (user.is_login) {
			fetchTranslations();
			fetchMedias();
		}
	}, [user]);

	return (
		<HashRouter>
			{user.is_login && (
				<div className="main-container">
					<Navbar />
					<Routes>
						<Route
							path="/"
							element={<Navigate to={"/content"} />}
						/>
						<Route path="/content" element={<Content />} />
						<Route
							path="/content/:model_id"
							element={<Content />}
						/>
						<Route
							path="/content/:model_id/:entry_id"
							element={<ContentItem />}
						/>
						<Route path="/media" element={<Media />} />
						<Route
							path="/media/:media_id"
							element={<MediaItem />}
						/>
						<Route
							path="/translations"
							element={<Translations />}
						/>
						<Route
							path="/translations/:directory"
							element={<Translations />}
						/>
						<Route
							path="/translations/:directory/:entry"
							element={<Translations />}
						/>
						<Route path="/auth/*" element={<Navigate to={"/"} />} />
					</Routes>
				</div>
			)}
			{!user.is_login && (
				<Routes>
					<Route path="/auth/login" element={<Login />} />
					<Route path="*" element={<Navigate to={"/auth/login"} />} />
				</Routes>
			)}
		</HashRouter>
	);
};

export default connect(null, mapDispatchToProps)(App);
