import React from "react";
import "./style.scss";
import { Link, useParams } from "react-router-dom";

const DirectoriesNav = ({ directories }) => {
	const { directory, entry } = useParams();

	return (
		<ul id="directories-nav">
			{directories.map((dir, index) => (
				<li
					className={
						directory == index ? "selected list-link" : "list-link"
					}
					key={index}
				>
					<Link to={`/translations/${index}`}>
						{dir.title} ({dir.entries.length})
					</Link>
				</li>
			))}
		</ul>
	);
};

export default DirectoriesNav;
