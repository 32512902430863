import React from "react";
import file_image from "../../../assets/images/document.png";
import "../../table/cell.scss";
import { baseURL } from "../../../services/axios";

const imgs = ["png", "jpg", "webp", "svg"];

const FileCell = ({ url, data }) => {
	if (!url) {
		url = "empty.empty";
	}
	const end_url = url.split(".")[url.split(".").length - 1];

	if (imgs.includes(end_url)) {
		return (
			<div className="table-cell file-cell">
				<div className="img-container">
					<img src={`${baseURL}${url}`} />
				</div>
				<span>{data.row.name}</span>
			</div>
		);
	}

	return (
		<div className="table-cell file-cell">
			<div className="img-container">
				<span className="ext">
					{url.split(".")[url.split(".").length - 1]}
				</span>
			</div>
			<span>{data.row.name}</span>
		</div>
	);
};

export default FileCell;
