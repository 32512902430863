// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main-navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: var(--navbar-height);
  background-color: #202020;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 0 20px;
}
#main-navbar * {
  color: #fff;
  font-family: var(--title-family);
}
#main-navbar ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding: 5px;
}
#main-navbar ul li {
  list-style: none;
}
#main-navbar ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  text-decoration: none;
  transition: 0.3s;
}
#main-navbar ul li a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}
#main-navbar ul li.selected a {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.05);
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/navbar/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,4BAAA;EACA,yBAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,WAAA;EACA,eAAA;AACD;AACC;EACC,WAAA;EACA,gCAAA;AACF;AAEC;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,QAAA;EACA,YAAA;AAAF;AAEE;EACC,gBAAA;AAAH;AAEG;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;EACA,mCAAA;EACA,6BAAA;EACA,qBAAA;EACA,gBAAA;AAAJ;AAEI;EACC,0CAAA;EACA,sCAAA;AAAL;AAKI;EACC,2CAAA;EACA,uCAAA;AAHL","sourcesContent":["#main-navbar {\n\tdisplay: flex;\n\tflex-direction: column;\n\tjustify-content: center;\n\theight: var(--navbar-height);\n\tbackground-color: #202020;\n\tposition: fixed;\n\ttop: 0;\n\tleft: 0;\n\twidth: 100%;\n\tz-index: 10;\n\tpadding: 0 20px;\n\n\t* {\n\t\tcolor: #fff;\n\t\tfont-family: var(--title-family);\n\t}\n\n\tul {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\t\tjustify-content: flex-start;\n\t\tgap: 5px;\n\t\tpadding: 5px;\n\n\t\tli {\n\t\t\tlist-style: none;\n\n\t\t\ta {\n\t\t\t\tdisplay: flex;\n\t\t\t\talign-items: center;\n\t\t\t\tjustify-content: center;\n\t\t\t\tpadding: 4px 8px;\n\t\t\t\tborder-radius: var(--border-radius);\n\t\t\t\tborder: 1px solid transparent;\n\t\t\t\ttext-decoration: none;\n\t\t\t\ttransition: 0.3s;\n\n\t\t\t\t&:hover {\n\t\t\t\t\tbackground-color: rgba($color: #fff, $alpha: 0.1);\n\t\t\t\t\tborder-color: rgba($color: #fff, $alpha: 0.1);\n\t\t\t\t}\n\t\t\t}\n\n\t\t\t&.selected {\n\t\t\t\ta {\n\t\t\t\t\tbackground-color: rgba($color: #fff, $alpha: 0.05);\n\t\t\t\t\tborder-color: rgba($color: #fff, $alpha: 0.05);\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
