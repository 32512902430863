import { TextField } from "@mui/material";
import React from "react";

const ContentNumberField = ({ label, value, onChange }) => {
	return (
		<TextField
			size="small"
			type="number"
			step="0.1"
			placeholder={label}
			value={value}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
};

export default ContentNumberField;
