import React, { useEffect, useState } from "react";
import axios from "../../../../../services/axios";
import "./style.scss";
import { Button, IconButton, Modal, Skeleton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ContentRelField = ({ label, value, onChange, field }) => {
	const [modelItems, setModelItems] = useState([]);
	const [showPopup, setShowPopup] = useState(false);
	const load_items = async (model_id, show_popup = false) => {
		if (show_popup) {
			setShowPopup(true);
		}
		const items = await axios
			.get(`content/${model_id}/`)
			.then((e) => e.data)
			.catch((e) => []);
		setModelItems(items);
	};

	useEffect(() => {
		if (value) {
			load_items(field.reference_model_id);
		}
	}, []);

	const selected = modelItems.filter((item) => item.id == value);

	return (
		<>
			<div className={`rel-field-content ${value ? "full" : ""}`}>
				{!value ? (
					<Button
						variant="outlined"
						onClick={() =>
							load_items(field.reference_model_id, true)
						}
					>
						Add content
					</Button>
				) : (
					<div className="value-container">
						<div className="value-header">
							{selected.length ? (
								<span>{selected[0].type}</span>
							) : (
								<Skeleton
									style={{ borderRadius: 5 }}
									variant="rectangle"
									width={100}
									height={20}
								/>
							)}
							<IconButton
								size="small"
								onClick={() => onChange("")}
							>
								<CloseIcon style={{ fontSize: 14 }} />
							</IconButton>
						</div>
						<div className="value-content">
							<span>
								{selected.length ? (
									selected[0].title ? (
										selected[0].title
									) : (
										"Unnamed"
									)
								) : (
									<Skeleton
										style={{ borderRadius: 5 }}
										variant="rectangle"
										width={100}
										height={20}
									/>
								)}
							</span>
						</div>
					</div>
				)}
			</div>

			<Modal
				className="rel-field-popup"
				open={showPopup}
				onClose={() => setShowPopup(false)}
			>
				<div className="popup-center">
					<div className="popup-header">
						<span>Add existing entry</span>
						<IconButton onClick={() => setShowPopup(false)}>
							<CloseIcon />
						</IconButton>
					</div>
					<div className="popup-content">
						{modelItems.map((item, index) => (
							<div
								onClick={() => {
									onChange(item.id);
									setShowPopup(false);
								}}
								className="item-container"
								key={index}
							>
								<div className="item-header">
									<span>{item.type}</span>
								</div>
								<div className="item-content">
									<span>
										{item.title ? item.title : "Unnamed"}
									</span>
								</div>
							</div>
						))}
					</div>
				</div>
			</Modal>
		</>
	);
};

export default ContentRelField;
