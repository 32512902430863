import { TextField } from "@mui/material";
import React from "react";

const ContentTextField = ({ label, value, onChange }) => {
	return (
		<TextField
			size="small"
			placeholder={label}
			value={value}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
};

export default ContentTextField;
