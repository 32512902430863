// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#directories-nav {
  height: calc(100svh - 60px);
  position: fixed;
  top: var(--navbar-height);
  width: 180px;
  left: 0;
  background-color: #f0f0f0;
  border-right: 1px solid var(--clr-divider);
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  z-index: 1;
}
#directories-nav * {
  font-family: var(--title-family);
}
#directories-nav li {
  list-style: none;
}
#directories-nav li a {
  text-decoration: none;
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/translations/directories_nav/style.scss"],"names":[],"mappings":"AAAA;EACC,2BAAA;EACA,eAAA;EACA,yBAAA;EACA,YAAA;EACA,OAAA;EACA,yBAAA;EACA,0CAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,QAAA;EACA,UAAA;AACD;AACC;EACC,gCAAA;AACF;AAEC;EACC,gBAAA;AAAF;AACE;EACC,qBAAA;EACA,2BAAA;AACH","sourcesContent":["#directories-nav {\n\theight: calc(100svh - 60px);\n\tposition: fixed;\n\ttop: var(--navbar-height);\n\twidth: 180px;\n\tleft: 0;\n\tbackground-color: #f0f0f0;\n\tborder-right: 1px solid var(--clr-divider);\n\tpadding: 15px;\n\tdisplay: flex;\n\tflex-direction: column;\n\tgap: 5px;\n\tz-index: 1;\n\n\t* {\n\t\tfont-family: var(--title-family);\n\t}\n\n\tli {\n\t\tlist-style: none;\n\t\ta {\n\t\t\ttext-decoration: none;\n\t\t\tjustify-content: flex-start;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
