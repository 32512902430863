import React, { useContext, useEffect } from "react";
import AuthContext from "../../contexts/AuthContext";
import { connect } from "react-redux";
import { mapDispatchToProps, mapStateToProps } from "../../store/dispatcher";
import { Link, useNavigate, useParams } from "react-router-dom";
import DirectoriesNav from "../../components/translations/directories_nav/DirectoriesNav";
import EntriesNav from "../../components/translations/entries_nav/EntriesNav";
import "./style.scss";
import EntryItem from "../../components/translations/entry_item/EntryItem";
import NewModal from "../../components/translations/new_modal/NewModal";

const Translations = ({ translations, fetchTranslations }) => {
	const { locales } = useContext(AuthContext);
	const { directory, entry } = useParams();
	const navigate = useNavigate();

	const show_directory_nav =
		directory && !isNaN(directory) && directory <= translations.length - 1;

	const show_entry_form =
		show_directory_nav &&
		entry &&
		!isNaN(entry) &&
		entry <= translations[directory].entries.length;

	useEffect(() => {
		if (directory == undefined && translations.length) {
			navigate(`/translations/0`);
		}
	}, [directory]);

	return (
		<div id="translations">
			<DirectoriesNav directories={translations} />
			<NewModal
				open={entry == "new"}
				directories={translations}
				fetchTranslations={fetchTranslations}
				entries={
					show_directory_nav ? translations[directory].entries : []
				}
			/>
			{show_directory_nav && (
				<EntriesNav entries={translations[directory].entries} />
			)}
			{show_entry_form && (
				<EntryItem entry={translations[directory].entries[entry]} />
			)}
		</div>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(Translations);
