// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-viewer {
  width: 100%;
  border-radius: 4px;
  border: 1px solid var(--clr-divider);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-viewer img {
  height: 200px;
  max-width: 100%;
  object-fit: contain;
}
.media-viewer .non-picture {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
.media-viewer .non-picture span {
  font-weight: bold;
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/media/media_item/media_viewer/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,kBAAA;EACA,oCAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACD;AACC;EACC,aAAA;EACA,eAAA;EACA,mBAAA;AACF;AAEC;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,aAAA;AAAF;AACE;EACC,iBAAA;EACA,iBAAA;AACH","sourcesContent":[".media-viewer {\n\twidth: 100%;\n\tborder-radius: 4px;\n\tborder: 1px solid var(--clr-divider);\n\tpadding: 20px;\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\timg {\n\t\theight: 200px;\n\t\tmax-width: 100%;\n\t\tobject-fit: contain;\n\t}\n\n\t.non-picture {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\theight: 200px;\n\t\tspan {\n\t\t\tfont-weight: bold;\n\t\t\tfont-size: 1.2rem;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
