import React, { useContext } from "react";
import "../../../content/content_item/content_item_aside/style.scss";
import { MenuItem, Select } from "@mui/material";
import ContentItemContext from "../../../../contexts/ContentItemContext";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../../contexts/AuthContext";
import { LoadingButton } from "@mui/lab";
import MediaContext from "../../../../contexts/MediaContext";

const MediaItemAside = () => {
	const { currentLocale, update_locale, can_save, save, loading } =
		useContext(MediaContext);
	const { locales } = useContext(AuthContext);
	const { t } = useTranslation("locales");

	if (!locales || !currentLocale) return null;

	return (
		<aside className="content-item-aside">
			<span>aside</span>
			<div className="save-btn">
				<LoadingButton
					disabled={!can_save.current}
					onClick={save}
					variant="contained"
					loading={loading}
					fullWidth
				>
					Save changes
				</LoadingButton>
			</div>

			<div className="translation-container">
				<span className="translation-title">Translations</span>
				<Select
					size="small"
					labelId="demo-simple-select-label"
					className="translation-selector"
					value={currentLocale.locale}
					onChange={(e) => update_locale(e.target.value)}
				>
					{locales.map((local, index) => (
						<MenuItem key={index} value={local.locale}>
							{t(local.locale)}
						</MenuItem>
					))}
				</Select>
			</div>
		</aside>
	);
};

export default MediaItemAside;
