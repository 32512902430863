// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-modal .modal-center {
  background-color: var(--clr-background);
  outline: none;
  border: none;
  border-radius: var(--border-radius);
  width: 100%;
  max-width: 600px;
}
.new-modal .modal-center .modal-footer {
  padding: 10px;
  border-top: 1px solid var(--clr-divider);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.new-modal .modal-center .modal-header {
  padding: 10px;
  border-bottom: 1px solid var(--clr-divider);
}
.new-modal .modal-center .modal-header .title {
  font-weight: bold;
  font-size: 1rem;
}
.new-modal .modal-center .modal-header p {
  max-width: 400px;
}
.new-modal .modal-center .modal-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 15px;
  width: 100%;
  align-items: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/components/translations/new_modal/style.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;AACD;AACC;EACC,uCAAA;EACA,aAAA;EACA,YAAA;EACA,mCAAA;EACA,WAAA;EACA,gBAAA;AACF;AACE;EACC,aAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,yBAAA;EACA,SAAA;AACH;AAEE;EACC,aAAA;EACA,2CAAA;AAAH;AAEG;EACC,iBAAA;EACA,eAAA;AAAJ;AAGG;EACC,gBAAA;AADJ;AAKE;EACC,aAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;EACA,WAAA;EACA,uBAAA;AAHH","sourcesContent":[".new-modal {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\n\t.modal-center {\n\t\tbackground-color: var(--clr-background);\n\t\toutline: none;\n\t\tborder: none;\n\t\tborder-radius: var(--border-radius);\n\t\twidth: 100%;\n\t\tmax-width: 600px;\n\n\t\t.modal-footer {\n\t\t\tpadding: 10px;\n\t\t\tborder-top: 1px solid var(--clr-divider);\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\talign-items: center;\n\t\t\tjustify-content: flex-end;\n\t\t\tgap: 10px;\n\t\t}\n\n\t\t.modal-header {\n\t\t\tpadding: 10px;\n\t\t\tborder-bottom: 1px solid var(--clr-divider);\n\n\t\t\t.title {\n\t\t\t\tfont-weight: bold;\n\t\t\t\tfont-size: 1rem;\n\t\t\t}\n\n\t\t\tp {\n\t\t\t\tmax-width: 400px;\n\t\t\t}\n\t\t}\n\n\t\t.modal-content {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\tpadding: 15px;\n\t\t\tgap: 15px;\n\t\t\twidth: 100%;\n\t\t\talign-items: flex-start;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
