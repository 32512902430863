import React, { useState } from "react";
import "./style.scss";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeft from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Fade, Popover, Popper } from "@mui/material";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import CheckIcon from "@mui/icons-material/Check";

const TableFooter = ({
	selection_size,
	full_data,
	set_page = () => null,
	set_row_per_page = () => null,
	page_size_options,
}) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	return (
		<div className="content-table-footer">
			<span className="selected-rows">
				{selection_size} of {full_data.data.length} row(s) selected.
			</span>
			<div className="right-container">
				<div className="rows-per-page">
					<span>Rows per page</span>
					<div
						className="rows-btn"
						onClick={anchorEl ? handleClose : handleClick}
					>
						<span>{full_data.rows_per_page}</span>
						<UnfoldMoreIcon fontSize="inherit" />
					</div>
					<Popper
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						placement="top"
						transition
					>
						{({ TransitionProps }) => (
							<Fade {...TransitionProps}>
								<div className="content-table-row-per-page-container">
									{page_size_options.map((value, id) => (
										<div
											key={id}
											className={`row-per-page-prop ${
												value == full_data.rows_per_page
													? "selected"
													: ""
											}`}
											onClick={() => {
												setAnchorEl(null);
												set_row_per_page(value);
											}}
										>
											<span>{value}</span>
											<CheckIcon fontSize="inherit" />
										</div>
									))}
								</div>
							</Fade>
						)}
					</Popper>
				</div>
				<span>
					Page {full_data.current_page + 1} of {full_data.max_page}
				</span>

				<div className="move-btns">
					<button
						onClick={() => set_page("first")}
						className={`button-move double ${
							full_data.current_page == 0 ? "disabled" : ""
						}`}
					>
						<KeyboardDoubleArrowLeft fontSize="inherit" />
					</button>
					<button
						onClick={() => set_page(full_data.current_page - 1)}
						className={`button-move ${
							full_data.current_page == 0 ? "disabled" : ""
						}`}
					>
						<KeyboardArrowLeftIcon fontSize="inherit" />
					</button>
					<button
						onClick={() => set_page(full_data.current_page + 1)}
						className={`button-move ${
							full_data.current_page >= full_data.max_page - 1
								? "disabled"
								: ""
						}`}
					>
						<KeyboardArrowRightIcon fontSize="inherit" />
					</button>
					<button
						onClick={() => set_page("last")}
						className={`button-move double ${
							full_data.current_page >= full_data.max_page - 1
								? "disabled"
								: ""
						}`}
					>
						<KeyboardDoubleArrowRightIcon fontSize="inherit" />
					</button>
				</div>
			</div>
		</div>
	);
};

export default TableFooter;
