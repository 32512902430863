// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media-item-container {
  width: 100%;
  height: 100%;
}
.media-item-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 4px;
  border: 1px solid var(--clr-divider);
}
.media-item-container .non-picture {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-divider);
  border-radius: 4px;
}
.media-item-container .non-picture span {
  font-weight: bold;
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/components/main/render_media/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;EACA,YAAA;AACD;AACC;EACC,WAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,oCAAA;AACF;AAEC;EACC,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oCAAA;EACA,kBAAA;AAAF;AAEE;EACC,iBAAA;EACA,iBAAA;AAAH","sourcesContent":[".media-item-container {\n\twidth: 100%;\n\theight: 100%;\n\n\timg {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tobject-fit: contain;\n\t\tborder-radius: 4px;\n\t\tborder: 1px solid var(--clr-divider);\n\t}\n\n\t.non-picture {\n\t\twidth: 100%;\n\t\theight: 100%;\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tborder: 1px solid var(--clr-divider);\n\t\tborder-radius: 4px;\n\n\t\tspan {\n\t\t\tfont-weight: bold;\n\t\t\tfont-size: 1.2rem;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
