import React from "react";
import { image_meme } from "../../../main/render_media/RenderMedia";
import "./style.scss";
import FilePresentIcon from "@mui/icons-material/FilePresent";

const MediaViewer = ({ name, content }) => {
	const file_url = name ? name : "base.empty";
	const end_url = file_url.split(".")[file_url.split(".").length - 1];

	if (image_meme.includes(end_url)) {
		return (
			<div className="media-viewer">
				<img src={content} />
			</div>
		);
	}
	return (
		<div className="media-viewer">
			<div className="non-picture">
				<FilePresentIcon />
				<span>{end_url}</span>
			</div>
		</div>
	);
};

export default MediaViewer;
