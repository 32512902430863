// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-item-aside {
  position: fixed;
  top: calc(var(--navbar-height) + 70px);
  left: 0;
  background-color: #f0f0f0;
  border-right: 1px solid var(--clr-divider);
  height: calc(100svh - (var(--navbar-height) + 70px));
  width: 260px;
  padding: 20px;
}
.content-item-aside .translation-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.content-item-aside .translation-container .translation-title {
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}
.content-item-aside .translation-container .translation-selector {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/components/content/content_item/content_item_aside/style.scss"],"names":[],"mappings":"AAAA;EACC,eAAA;EACA,sCAAA;EACA,OAAA;EACA,yBAAA;EACA,0CAAA;EACA,oDAAA;EACA,YAAA;EACA,aAAA;AACD;AACC;EACC,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EACC,mBAAA;EACA,cAAA;EACA,iBAAA;AACH;AAEE;EACC,eAAA;AAAH","sourcesContent":[".content-item-aside {\n\tposition: fixed;\n\ttop: calc(var(--navbar-height) + 70px);\n\tleft: 0;\n\tbackground-color: #f0f0f0;\n\tborder-right: 1px solid var(--clr-divider);\n\theight: calc(100svh - calc(var(--navbar-height) + 70px));\n\twidth: 260px;\n\tpadding: 20px;\n\n\t.translation-container {\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tmargin-top: 20px;\n\n\t\t.translation-title {\n\t\t\tmargin-bottom: 10px;\n\t\t\tdisplay: block;\n\t\t\tfont-weight: bold;\n\t\t}\n\n\t\t.translation-selector {\n\t\t\tfont-size: 14px;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
