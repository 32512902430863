import React, { useContext } from "react";
import "./style.scss";
import ContentItemContext from "../../../../contexts/ContentItemContext";
import { Modal, Skeleton } from "@mui/material";
import ContentTextField from "./fields/ContentTextField";
import { useTranslation } from "react-i18next";
import ContentNumberField from "./fields/ContentNumberField";
import ContentRichTextField from "./fields/ContentRichTextField";
import ContentRelField from "./fields/ContentRelField";
import ContentMediaField from "./fields/ContentMediaField";
import { useParams } from "react-router-dom";
import NamingModal from "./naming_modal/NamingModal";

const ContentItemForm = () => {
	const { model, currentLocale, updatableItem, update_item } =
		useContext(ContentItemContext);
	const { model_id, entry_id } = useParams();
	const { t } = useTranslation("locales");

	if (!updatableItem) {
		return (
			<>
				<NamingModal open={entry_id == "new"} model={model} />
				<div className="content-item-form empty">
					<Skeleton
						variant="rectangular"
						style={{ borderRadius: 5 }}
						width={120}
						height={20}
					/>
					<Skeleton
						variant="rectangular"
						style={{ borderRadius: 5 }}
						width={400}
						height={200}
					/>
				</div>
			</>
		);
	}

	const fields = updatableItem[currentLocale.locale];

	return (
		<div className="content-item-form">
			{Object.entries(fields).map((item, index) => {
				const entry = item[1];
				const key = item[0];

				if (key == "id") return null;

				const field = model.fields.filter((e) => e.field_id == key)[0];

				const update_field = (value) => {
					update_item(currentLocale.locale, field.field_id, value);
				};

				return (
					<div className="content-input-block" key={index}>
						<span className="input-block-title">
							{field.name}
							{field.is_localized && (
								<span> | {t(currentLocale.locale)}</span>
							)}
						</span>
						{field.type == "text" ? (
							<ContentTextField
								label={field.name}
								value={entry}
								onChange={update_field}
							/>
						) : ["integer", "float"].includes(field.type) ? (
							<ContentNumberField
								label={field.name}
								value={entry}
								onChange={update_field}
							/>
						) : field.type == "rich_text" ? (
							<ContentRichTextField
								label={field.name}
								value={entry}
								onChange={update_field}
								locale={currentLocale.locale}
							/>
						) : field.type == "ref" ? (
							<ContentRelField
								label={field.name}
								field={field}
								value={entry}
								onChange={update_field}
							/>
						) : field.type == "media" ? (
							<ContentMediaField
								label={field.name}
								value={entry}
								onChange={update_field}
							/>
						) : null}
					</div>
				);
			})}
		</div>
	);
};

export default ContentItemForm;
